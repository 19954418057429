<template>
  <div v-if="metadata != null">
    <custom-cg-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      :details="details"
      translatePrefix="controlTower.pages"
      @gridReady="onGridReady"
      :reloadData="reloadData"
      @onReloadData="reloadData = false"
    />
  </div>
</template>

<script>
import { CustomCgCrudMetaView, helpers, metadataHelper } from '@cargill/shared'
import service from '../api/takeUpReportInformationMasterService'
import takeUpLotReportInformationSummaryService from '../api/takeUpLotReportInformationSummaryService'
import FileDownload from 'js-file-download'

export default {
  components: { CustomCgCrudMetaView },
  data() {
    return {
      reloadData: false,
      metadata: null,
      crudService: service,
      details: {}
    }
  },
  methods: {
    async getLotReportInformationSummaryMeta() {
      const meta = await takeUpLotReportInformationSummaryService.getMeta()
      helpers.processMetaDetails(
        meta,
        'takeUpLotReportInformationSummary',
        'bookId',
        'book',
        takeUpLotReportInformationSummaryService
      )
      return meta
    },
    async getMetaDetails() {
      return await Promise.all([this.getLotReportInformationSummaryMeta()])
    },
    async getMeta() {
      let [meta, metaDetails] = await Promise.all([
        service.getMetaWithValidation(),
        this.getMetaDetails()
      ])
      meta.actions = [
        {
          title: this.$t(
            'controlTower.pages.takeUpReportInformationMaster.messages.calculateReport'
          ),
          className: 'fas fa-user-chart',
          enable: true,
          callback: ({ node }) => {
            takeUpLotReportInformationSummaryService
              .calculateReportByBookId(node.data.id)
              .then(() => {
                this.reloadData = true
              })
          }
        }
      ]
      meta.details = metaDetails
      meta.id = 'takeUpReportInformationMaster'
      return meta
    },
    onGridReady() {
      const crudActionsToolbar = helpers.findComponent(
        this,
        'BcCrudActionsToolbar'
      )
      const importAction = crudActionsToolbar.actions.find(
        (action) => action.titleKey == 'core.misc.import'
      )

      if (importAction) {
        importAction.canShow = true
        importAction.titleKey = 'controlTower.misc.exportCsv'
        importAction.onClick = async () => {
          const data =
            await takeUpLotReportInformationSummaryService.exportItasCsv(
              service.lastAppliedFilter
            )
          FileDownload(
            new Blob([data], { type: 'text/csv' }),
            `Relatorio_Lotes_ITAS.csv`
          )
        }
        importAction.faIcon = 'fa-file-csv'
        crudActionsToolbar.$forceUpdate()
      }
    }
  },
  created() {
    this.getMeta().then(async (meta) => {
      await metadataHelper.buildMetadata(meta, service)
      this.details = helpers.createDetailsObject(meta, 'controlTower.pages')
      this.metadata = meta
    })
  }
}
</script>

<style scoped>
::v-deep .ag-selection-checkbox {
  display: none !important;
}
::v-deep .table-toolbar__select {
  visibility: hidden;
}
</style>
