import {
  api,
  createCrudService,
  handleError,
  i18n,
  notify
} from '@cargill/shared'
import _ from 'lodash'

const service = createCrudService(
  '/api/controltower/takeUpLotReportInformationSummary',
  api,
  { importLogUrl: '/api/controltower/importLog' }
)

service.exportItasCsv = async (filter) => {
  const baseUrl =
    '/api/controltower/takeUpLotReportInformationSummary/exportItasCsv'
  const url = filter ? `${baseUrl}?${filter}` : baseUrl
  const response = await api.get(url, {
    responseType: 'text/csv'
  })
  return response?.data
}

service.calculateReportByBookId = async (bookId) => {
  try {
    const response = await api.put(
      `/api/controltower/takeUpLotReportInformationSummary/calculateReportByBookId/${bookId}`
    )
    if (_.isEmpty(response.data.errorMsg)) {
      const message = i18n.t(
        'controlTower.pages.takeUpReportInformationMaster.messages.calculateReportSuccessMsg',
        response.data
      )
      notify.success({
        title: message
      })
    } else {
      notify.error({
        title: i18n.t(response.data.errorMsg)
      })
    }
    return response.data
  } catch (e) {
    handleError(e)
  }
}

export default service
